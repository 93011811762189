// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
footer {
  background-color: rgba(0, 0, 0, 0.25);
  color: white; 
  padding: 5px 20px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  position: fixed; 
  bottom: 0; 
  left: 0;
  right: 0; 
  box-sizing: border-box; 
}

footer p {
  margin: 0; 
}

footer a {
  color: white; 
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,qCAAqC;EACrC,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,SAAS;EACT,OAAO;EACP,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":["/* Footer.css */\nfooter {\n  background-color: rgba(0, 0, 0, 0.25);\n  color: white; \n  padding: 5px 20px; \n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%; \n  position: fixed; \n  bottom: 0; \n  left: 0;\n  right: 0; \n  box-sizing: border-box; \n}\n\nfooter p {\n  margin: 0; \n}\n\nfooter a {\n  color: white; \n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
