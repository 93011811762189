// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Leaderboard.css */
.leaderboard-container {
  margin: 0 auto;
  padding: 20px;
}

.leaderboard-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 2px solid #01B9F1;
  border-radius: 15px;
  overflow: hidden;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #01B9F1;
}

.leaderboard-table th {
  background-color: transparent;
  color: #01B9F1;
  font-weight: bold;
}

.leaderboard-table td {
  background-color: transparent;
  color: white;
}

.leaderboard-table tr:last-child td {
  border-bottom: none;
}

.table-responsive {
  overflow-x: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Leaderboard.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* Leaderboard.css */\n.leaderboard-container {\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.leaderboard-table {\n  width: 100%;\n  border-collapse: separate;\n  border-spacing: 0;\n  border: 2px solid #01B9F1;\n  border-radius: 15px;\n  overflow: hidden;\n}\n\n.leaderboard-table th,\n.leaderboard-table td {\n  padding: 12px;\n  text-align: left;\n  border-bottom: 1px solid #01B9F1;\n}\n\n.leaderboard-table th {\n  background-color: transparent;\n  color: #01B9F1;\n  font-weight: bold;\n}\n\n.leaderboard-table td {\n  background-color: transparent;\n  color: white;\n}\n\n.leaderboard-table tr:last-child td {\n  border-bottom: none;\n}\n\n.table-responsive {\n  overflow-x: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
